.emoji-rater {

  margin: 0 10px;

  .react-rater > * + * {
    margin-left: 0.5rem;
  }

  .react-rater div {
    display: inline-block;
  }

  svg {
    cursor: pointer;
  }
  circle, path {
    transition: stroke .3s, fill .3s;
  }
}
